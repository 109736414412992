import bookSVG from '../icons/icon_chaseLinkBook.svg';
import computerSVG from '../icons/icon_chaseLinkComputer.svg';
import leafSVG from '../icons/icon_chaseLinkLeaf.svg';
import gearSVG from '../icons/icon_chaseLinkGears.svg';
import { getAEMBackendUrls } from '../utils/Utils';
export const BRAND_LAND_ROVER = 'LandRover';
export const BRAND = 'Fisker';
export const onlineProfileIdentifier = "54297629";
export const logLabel = {
    error: "ERROR",
    debug: "DEBUG",
    info: "INFO"
};
export const beforeMyAccCall = "Invoking my account api call";
export const afterMyAccCall = "Successfully retrieved account's data";
export const logErrCode = {
    reactErr: "React_Exception",
    aemErr: "AEM_Exception"
};
export const logErrDetails = {
    reactErr: "UI exception occured : ",
    aemErr: "Exception occurred while connecting to AEM : ",
    myAcc: "Exception occurred while connecting to My Account Experience API : ",
    logger: "Exception occurred while connecting to Logger API : ",
};
export const API_URLS = {
    MyAccount: "/v1/myaccount",
    Logger: "/v1/logging",
    Header: '/v1/headers',
    TurnIn: '/v1/turnin',
    Respond: '/v1/respond'
};
export const AEM_CONTENT_URLS = {
    PROD: {
        BACKEND_URL: 'https://static.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static.chasecdn.com',
    },
    NON_PROD: {
        BACKEND_URL: 'https://ccbcontent-qa2.chase.com',
        IMAGES_BASE_URL_QA2: 'https://static2-qa2.chasecdn.com',
    }
};
export const PDF = 'content-link';
export const FAQ_LINK = '/faq';
export const CONTACT_LINK = '/contact-us';
export const LEASE_COLLAPSE_TYPE = 'collapse';
export const LEASE_RETURN_LAND_ROVER_TITLE = 'return your land rover';
export const LEASE_RETURN_DATA = {
    dealerLocationLink: 'https://www.landroverusa.com/national-dealer-locator.html',
};
export const ALERT_TEXTS = {
    AlertHeading: " Your payment's past due",
    AlertText: ` Please make a payment as soon as you can so you don't fall
  behind on your account. If you've already made your payment,
  you're all set.`,
};
export const TIMED_MODAL = 'Timed Modal';
export const UNTIMED_MODAL = 'Untimed Modal';
export const RETAILER_STORE = `YOUR LAND ROVER RETAILER`;
export const ACCOUNTS_CHASELINKS_ARRAY_1 = [
    {
        URL: 'https://lrfg-uat.chase.com/_nuxt/icon-manage-autoplay.ke3yH-aX.svg',
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Review pay-from accounts', link: 'News' },
    { URL: bookSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_CHASELINKS_ARRAY_2 = [
    {
        URL: leafSVG,
        text: 'Manage automatic payments ',
        link: 'News',
    },
    { URL: computerSVG, text: 'Send a secure message', link: 'News' },
    { URL: gearSVG, text: 'Manage paperless ', link: 'News' },
];
export const ACCOUNTS_DATA = {
    exploreMoreHeading: ' EXPLORE MORE WITH LAND ROVER',
    toolsHeading: 'YOUR AUTO TOOLS',
    manageHeading: 'MANAGE YOUR ACCOUNT AT CHASE.COM',
    elseHeading: 'LOOKING FOR SOMETHING ELSE? VISIT',
    chaseHeading: 'CHASE.COM',
    lastPaymentAmount: {
        heading: 'LAST PAYMENT AMOUNT',
        value: '$310.00',
    },
    lastPaymentDate: {
        heading: 'LAST PAYMENT DATE',
        value: 'JUN 2, 2021',
    },
    mailingAddress: {
        heading: 'ACCOUNT MAILING ADDRESS',
        row1: '1317 TILLAMACK ST',
        row2: 'LOAN MID-1 CYCLE',
        row3: ' BILLINGS, MT 59102-1022',
    },
    vinDetails: {
        heading: 'VIN',
        hiddenValue: '*********919584',
        actualValue: 'SALCP2FX3PH919584',
    },
    accDetails: {
        heading: 'Account Number',
        hiddenValue: '*****9325511',
        actualValue: '12117209325511',
    },
    loanTerm: {
        heading: 'Loan Term',
        popoverText: `This is your original loan term. If you've requested
    to defer any payments during your loan, your current
    loan term may be different than what is shown here.`,
    },
    organisationDate: {
        heading: 'Origination date',
        value: 'May 15, 2022',
    },
    accountDetailsHeading: 'land rover account details',
    accountProgress: {
        heading: 'COMPLETED PAYMENTS',
        value: '11 / 60',
        popOverText: `  This is your original loan term. If you’ve requested to
  defer any payments during your loan, your completed and
  total payments required to meet the terms of your agreement
  may be different than what is shown here.`,
    },
    remainingBalance: {
        heading: 'REMAINING BALANCE',
        value: '$14,414.71',
        popoverText: ` Your remaining balance is not the same as your payoff
  amount. Go to chase.com to get your payoff quote`,
    },
    intrestRate: { heading: 'INTEREST RATE', value: '2.74%' },
    mainCarImg: '/content/dam/unified-assets/photography/partner/fisker/FiskerMyaccountsImage.webp',
    maiHeading: `CONGRATULATIONS ON YOUR LAND ROVER'S FIRST ANNIVERSARY!`,
    mainCarYear: '2023 Land Rover',
    mainCarName: 'Discovery Sport',
    paymentDetailsHeading: 'SEE PAYMENT DETAILS',
    paymentDetailsData: [
        {
            heading: 'Current payment due',
            value: '$301.25',
        },
        {
            heading: 'Amount past due',
            value: '$301.25',
        },
        {
            heading: 'Late charges',
            value: '$30.00',
        },
        {
            heading: 'Other fees',
            value: '$01.00',
        },
    ],
    paymentInfoFinalAmount: {
        heading: 'Total amount due',
        value: '$632.50',
    },
    paymentInfoMonthlyPayment: {
        heading: 'Monthly payment',
        value: '$301.25',
    },
    accountDetailsLink: 'Account Details',
    amountsDue: { heading: 'Amount due', value: '$632.50' },
    dueDate: { heading: 'Due Date', value: 'Nov 13, 2023' },
    editButton: 'EDIT',
};
export const CAR_COLOUR_SELECT = ['red', 'green', 'grey'];
//AEM Configs
export const AEM_CONFIG = {
    ...getAEMBackendUrls(),
    VERSION: 'latest',
    FALLBACK_LAN: 'en',
};
export const CAROUSELTYPE = 'carousel';
export const AEM_MODELS = {
    LINKLIST: 'linkList',
    ICONLIST: 'iconList',
    CONTENTLIST: 'contentList',
    CAROUSEL: 'carousel',
    DIALOG: 'dialog',
    TILE: 'tile',
    STRUCTUREDCONTENTLIST: 'structuredContentList',
    LEGALCONTAINER: 'legalContainer',
    HEADER: 'header',
};
export const RETURN_OBJ = {
    returnObjects: true,
};
export const LINK_TYPES = {
    ANQUER: 'anquer',
    SUP: 'sup',
    BOLD: 'bold',
};
export const AEM_NAME_SPACES = {
    DEFAULT: 'auto/lrfg',
    COMMON: 'auto/common',
};
export const AEM_TAGS = {
    COMMON_TAG: 'auto/oem/fisker/common',
    HOMEPAGE_TAG: 'auto/oem/fisker/homepage',
    ERROR_PAGE_TAG: 'auto/oem/fisker/error-page',
    SITEMAPPAGE_TAG: 'auto/oem/fisker/sitemap',
    CONTACTUS_TAG: 'auto/oem/fisker/contact-us',
    FAQSPAGE_TAG: 'auto/oem/fisker/faqs',
    PREAPPROVALPAGE_TAG: 'auto/oem/lrfg/preapprovalpage',
    ACCOUNTS_TAG: 'auto/oem/fisker/myaccount',
};
export const AEM_IMAGES_PARAM = {
    SPLIT_WITH: '_Desktop1.jpg',
    JPG_EXT_PATH_DESKTOP: '_Desktop1.jpg',
    JPG_EXT_PATH_MOBILE: '_Mobile1.jpg',
    WEBP_EXT_PATH_DESKTOP: '_Desktop2.webp',
    WEBP_EXT_PATH_MOBILE: '_Mobile2.webp',
    CARD_SPLIT_PATH: '_1.jpg',
    CARD_SPLIT_PATH_WEBP: '_2.webp',
};
export const AEM_CONTENT_TRANSLATION_PARAM = {
    COMMON_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: { tag: AEM_TAGS.COMMON_TAG, version: 'latest' },
    },
    HOMEPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.HOMEPAGE_TAG,
            version: 'latest',
        },
    },
    ERROR_PAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ERROR_PAGE_TAG,
            version: 'latest',
        },
    },
    SITEMAPPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.SITEMAPPAGE_TAG,
            version: 'latest',
        },
    },
    CONTACTUS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.CONTACTUS_TAG,
            version: 'latest',
        },
    },
    FAQSPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.FAQSPAGE_TAG,
            version: 'latest',
        },
    },
    ACCOUNTS_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.ACCOUNTS_TAG,
            version: 'latest',
        },
    },
    PREAPPROVALPAGE_TAG: {
        [AEM_NAME_SPACES.DEFAULT]: {
            tag: AEM_TAGS.PREAPPROVALPAGE_TAG,
            version: 'latest',
        },
    },
};
export const NO_CONTENT_FOUND = 'No Content Found';
export const ARROW_WHITE_POINTING_RIGHT = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg`;
export const GREEN_ARROW_ICON = `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg`;
export const OEM_BUTTON_TYPES = { PRIMARY: 'primary', SECONDARY: 'secondary' };
export const FAQ_SEARCH_RESULT_TEXT = {
    SHOW_WITH_COUNT: 'Showing counts result for "searchValue"',
    SHOW_WITHOUT_COUNT: 'No results found. Try a different search or',
};
export const ANALYTICS_CLASSNAME = {
    EXTERNAL_LINK: 'chaseanalytics-opt-exlnk',
    INTERNAL_LINK: 'chaseanalytics-track-link',
    INPUT_ELEMENT: 'chaseanalytics-track-element',
    SELECT_OPTION: 'chaseanalytics-track-option',
    MODAL_LINK: 'chaseanalytics-track-modal',
};
export const MODAL_NAME = {
    EXTERNAL_SPEED_BUMP: 'pl_leave_fin',
    INTERNAL_SPEED_BUMP: 'pl_connect_chase',
};
export const FORM_NAME = {
    'header': 'pl_nav_form',
    'home': 'pl_home_rotator',
    'finance': 'pl_finance_page',
    'account': 'pl_account_page',
    'faq': 'pl_faq_page',
    'contact': 'pl_contactus_page',
    'turnin': 'pl_turnin_page',
    'respond': 'pl_respond_page'
};
export const OEM_IMAGE_CATEGORY = {
    small: 'SM',
    large: 'LG',
    extraLarge: 'XL',
};
export const ADDITIONAL_HELP = 'Need additional help?';
export const ERROR_PAGE_OBJ = {
    imageLink: {
        desktopWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Desktop2.webp`,
        imageWebpUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Mobile2.webp`,
        desktopJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Desktop1.jpg`,
        imageJpegUrl: `${AEM_CONFIG.IMAGES_BASE_URL_QA2}/content/dam/unified-assets/photography/partner/fisker/ErrorPage_HeroImage_Mobile1.jpg`
    },
    heading: "IT LOOKS LIKE THIS PART OF OUR SITE ISN'T WORKING RIGHT NOW",
    description: [
        {
            text: 'Please try again later.',
        },
        {
            text: 'Thanks for your patience.',
        },
    ],
};
export const PROTECTED_URLS = ['/myaccount', '/myoffers', '/lease-end-turnin', '/lease-end-turnin-confirmation', '/logout'];
export const QA_AUTH_URL_DOMAIN = 'https://qac2-secure01ea.chase.com/';
export const PROD_AUTH_URL_DOMAIN = 'https://secure.chase.com/';
export const NAV_KEYS = "web/auth/?navKey=oemNavFisker&jp_cdf=FF_LOGIN&context=OEM_FISKER&treatment=FISKER&financialBrandName=Fisker+Finance&landingScreen=";
export const PROD_LOGIN_URL = `${PROD_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const QA_LOGIN_URL = `${QA_AUTH_URL_DOMAIN}${NAV_KEYS}`;
export const LOGOUT_PATH = '#/dashboard/signout/partner/OEM_FISKER'; // 'web/auth/router?context=LOGOFF'
export const PROD_LOGOUT_URL = `${PROD_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const QA_LOGOUT_URL = `${QA_AUTH_URL_DOMAIN}${LOGOUT_PATH}`;
export const EXTEND_SESSION_PATH = "web/auth/user/session/extend";
export const PROD_EXTEND_SESSION_URL = `${PROD_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const QA_EXTEND_SESSION_URL = `${QA_AUTH_URL_DOMAIN}${EXTEND_SESSION_PATH}`;
export const AEM_LOGOUT_PATH = '/logout';
export const PAYMENT_PATH = 'web/auth/dashboard?navKey=requestDashboard&jp_cdf=FF_MA_PMT';
export const CHASE_VISIT_PATH = 'web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=FF_MA_VISIT';
export const FF_MA_PMT = 'web/auth/dashboard?jp_cdf=FF_MA_PMT';
export const AEM_PAYMENT_PATH = '/payment';
export const PAYMENT_PATH_LANDING_AFTER_AUTH = '/myaccount';
export const INACTIVE_TIMEOUT_DETAILS = {
    timeout: 1000 * 60 * 6, // 6 mins
    warningTimeout: 1000 * 60 // 1 min
};
export const PAGE_TITLES = {
    "/": 'Financing a Fisker - Fisker Financial Group',
    "/myaccount": 'Your Fisker Financial Group Account',
    "/sitemap": 'Sitemap',
    "/pay-manage": 'No Active Account',
    "/myoffers": 'You are pre-approved',
    "/error": 'Error page',
    "/oops": 'Oops, page not found',
    "/faq": 'FAQs - Fisker Financial Group',
    "/contact-us": 'Contact us - Fisker Financial Group',
    "/respond": 'Respond - Fisker Financial Group'
};
export const ACCOUNT_STATUS = {
    ACTIVE: 'ACTIVE',
    EXTENDED: 'EXTENDED'
};
export const ACCOUNT_TYPE = {
    LOAN: 'LOAN',
    LEASE: 'LEASE'
};
export const DATE_ACTION = {
    ADD: 'ADD',
    SUBSTRACT: 'SUBSTRACT'
};
export const ALERT_TYPE = {
    ACTION: 'Action',
    INFO: 'Info',
    PROMO: 'Promo'
};
export const DOUBLE_OPEN_CURLY_BRACKETS = "{{";
export const DOUBLE_CLOSE_CURLY_BRACKETS = "}}";
export const SIGN_IN = 'Sign In';
export const SIGN_OUT = 'Sign Out';
export const PAY_AS_A_CUSTOMER = 'PayAsACustomer';
export const PRELOADING_IMAGES = {
    preloadImageurls: [
        '/content/dam/unified-assets/photography/partner/fisker/HomePage_HeroImage_Desktop2.webp',
        '/content/dam/unified-assets/photography/partner/fisker/HomePage_HeroImage_Mobile2.webp',
        '/content/dam/unified-assets/photography/partner/fisker/FiskerMyaccountsImage.webp',
    ]
};
export const OPTIONS = ["YES", "NO"];
export const VEHICLE_OPERABLE_OPTIONS = [{
        value: "OPTION-1",
        label: "Option 1"
    }, {
        value: "OPTION-2",
        label: "Option 2"
    }, {
        label: "My vehicle is inoperable",
        value: "VEHICLE-INOPERABLE"
    }, {
        label: "No offer received (would like further info)",
        value: "NO-OFFER-RECEIVED",
        content: "By selecting either Option 1 or 2, you agree that Chase may share your contact information and information about your Fisker Ocean with Rivian."
    }];
export const CONSENT_OPTIONS = [{
        value: "YES",
        label: "Yes"
    }, {
        value: "NO",
        label: "No"
    }, {
        value: "NOT-APPLICABLE",
        label: "Not applicable"
    }];
export const MAX_LENGTH = 40;
export const MAX_VIN_LENGTH = 17;
export const MAX_PHONE_LENGTH = 15;
export const MAX_EMAIL_LENGTH = 320;
export const LABELS = {
    "vehicleIdentificationNumber": "Vehicle Identification Number (VIN)*",
    "firstName": "First Name*",
    "lastName": "Last Name*",
    "phoneNumber": "Phone Number*",
    "emailAddress": "Email*",
    "consentCategoryText": "Do you want to participate in either option 1 or 2 as specified in your letter? Or, do you want your vehicle to be inspected because you believe your vehicle is inoperable?*",
    "globalTelephoneConsentStatusText": "If you are represented by an attorney, may JPMorganChase contact you directly to discuss your selection?",
};
export const CONTENT = "By completing this form you agree that JPMorganChase may share your contact information and information about your Fisker Ocean with Rivian.";
export const CONTENT_FAQ = [
    {
        question: "I have questions about the Fisker offer letter. Who should I contact?",
        answers: [
            "Please visit fiskerfinance.com/respond and complete the online form. Our Chase representatives are available at 855-381-8658, Monday through Friday from 9:00am to 6:30pm EST.",
            "If you are represented by an attorney, please consult your attorney about any questions regarding the offer."
        ]
    },
    {
        question: "I haven’t received an offer. Do I qualify?",
        answers: [
            "You must have an offer to participate in this program, and even if you have an offer you must meet the qualifications set forth in the offer (for example, you must still own your Fisker Ocean). If you did not receive an offer and you believe that this was an error, please visit fiskerfinance.com/respond and share your information. A Chase representative will follow up with you."
        ]
    },
    {
        question: "Are all Fisker Owners receiving the same offer?",
        answers: [
            "All Fisker owners who meet the qualifications laid out in the offer letter and financed through Chase and have an active loan have the opportunity to trade in their Ocean. The specific details of the offer amounts were based on a third-party valuation.",
            "Not every Fisker car owner who financed through Chase received an offer. For those who did, the offer was calculated based on the original MSRP price of their car and a specific factor related to the car's trim package that was provided pre bankruptcy by ALG. It's important to know that Fisker changed its prices over time, so the offer is based on the car's MSRP when it was originally bought. Even if two customers have the same trim package, they might receive different offer amounts if their cars had different features included in the MSRP. For example, two people who bought the same trim of Fisker might have different offers because of extra options if it was in the MSRP."
        ]
    },
    {
        question: "How did you come up with the valuation?",
        answers: [
            "The value listed in your offer is calculated by ALG data, a third-party, a subsidiary of J.D. Power, that delivers residual values for all makes, models, and trim packages of vehicles sold in the United States. ALG is the leading provider of forecasted values for auto lenders, and Chase uses ALG valuations for many purposes in its Auto Lending business. There are a variety of variables that impact actual residual value of a vehicle including macro, industry and market conditions. We applied the ALG factor for a Fisker Ocean of your particular trim package to your individual MSRP (including any optional equipment you purchased) to calculate the amount in your individual offer. We did not have access to individual cars so no additional factors (e.g, condition, mileage, etc.) were included in the valuation. The ALG values used were provided to Chase by ALG pre Fisker bankruptcy."
        ]
    },
    {
        question: "Why did you use ALG?",
        answers: [
            "Our goal was to mitigate the impact of Fisker’s bankruptcy on you so we used ALG value factors established before Fisker filed for bankruptcy to ensure the evaluation did not take the Fisker bankruptcy into account. We also applied the factor for 12 month old vehicles to all Fisker Oceans we financed regardless of age because as of March 2024, any Fisker of any trim financed by Chase was at least 12 months old."
        ]
    },
    {
        question: "Why isn’t Chase giving loan forgiveness on these vehicles?",
        answers: [
            "Chase is providing trade in offers that value your Fisker vehicle well above market value and provide opportunities to access incentives and tax credits that may be available if you qualify. Our goal is to offer you options that meet your needs and mitigate the impact the Fisker bankruptcy has had on the car’s value."
        ]
    },
    {
        question: "Why did you choose Rivian as your partner for these options?",
        answers: [
            "Chase has been working to research solutions and establish a partnership with Rivian, an electric vehicle manufacturer passionately dedicated to advancing EV technology, to provide options if you would like to get out of your Fisker. Rivian is an award-winning leader in customer satisfaction, having won Consumer Reports’ owner satisfaction survey for two consecutive years. Additionally, Rivian has geographic locations that align to the Fisker Finance customer population."
        ]
    },
    {
        question: "Have you shared my information with Rivian?",
        answers: [
            "No. We will only share your information with Rivian if you selected yes on the fiskerfinance.com/respond page allowing us to provide your information to Rivian. You are welcome to contact Rivian to explore vehicles options and offers. However, our Chase representatives will answer questions about your offer and review agreements that need to be signed."
        ]
    },
    {
        question: "Can I stop paying my loan while I review my offer?",
        answers: [
            "No. Customers are still responsible to make loan payments."
        ]
    },
    {
        question: "What happens after I submit the online form?",
        answers: [
            "Once you submit the online form and you agreed to allow Chase to contact you directly, one of our Chase representatives will be in touch with you. Once you verify your contact information and advise Chase how you would like to proceed, Chase will prepare a written settlement agreement and share it either with you or, if applicable, your attorney.  Once you return the executed settlement agreement and necessary documents, we will put you in touch with Rivian to finalize the offer."
        ]
    },
    {
        question: "If I have a balance greater than my offer amount, how will the outstanding balance be applied?",
        answers: [
            "If the balance remaining is greater than your offer amount, you may be able to incorporate payment of the remaining balance into your new Rivian lease or retail installment contract. You also have the option to continue to make your monthly payments until the promissory note is paid in full or, as always, you can pay off the full remaining balance at any time."
        ]
    },
    {
        question: "Why can’t Chase re-amortize my old loan, making it more affordable?",
        answers: [
            "We encourage you to consider the solution in the letter your received. If you trade in your Fisker any remaining debt that you roll into a new vehicle purchase or lease effectively will be reamortized over the term of your new loan or lease."
        ]
    },
    {
        question: "What if I choose not to accept my offer?",
        answers: [
            "If you choose to decline the offer, you will continue to proceed as you did before you received the offer. If you are represented by an attorney and part of an arbitration, that process will proceed."
        ]
    },
    {
        question: "I owe more on my Fisker than the value offered for it.  What does that mean for me if I want to trade in with Rivian?",
        answers: [
            "If you owe more on your outstanding Fisker loan than the value your letter places on your Fisker you have several options. People do trade in vehicles where they owe more on the vehicle than the value of their trade, this is called negative equity. Typically, when someone does that they finance the remaining balance as part of the purchase or lease of the new vehicle. You have that option if your financing provider agrees to it. Alternatively, you can pay off the remaining balance of your existing loan. You also have an option that is not typically available to people trading a car when they owe more than the value of the vehicle: you can just keep making the monthly payments on your existing loan. Lenders usually will not release their lien on a vehicle’s title without receiving a full payoff. Chase is not insisting on that for participants in this program. Chase will release its lien if you transfer your car to Rivian as part of this program. However, that will mean you will have the payment on your new Rivian and the payment on your existing Fisker account going forward. Your monthly payment on the Fisker account would remain the same, but interest would only accrue based on the outstanding principal balance."
        ]
    },
    {
        question: "What incentives are available to me as part of this offer?",
        answers: [
            "Rivian has a number of incentives that you may qualify for, and we encourage you to review your options with a Rivian representative. In addition to the enhanced trade in offer, and Rivian incentives, there may also be benefits in the form of tax credits for trading (depending on your state rules) and $7,500 tax credit for leasing a Rivian (as long as the program remains available)."
        ]
    },
    {
        question: "Will Rivian’s incentives change?",
        answers: [
            "Rivian runs their programs monthly, and you are able to take advantage of the programs they have available in conjunction with the enhanced offer, pending you qualify for the program. Chase cannot guarantee Rivian’s programs, and would expect changes month to month. Chase is trying to provide you options, however, it is ultimately your decision if you would like to take advantage of the Rivian programs and enhanced offer."
        ]
    },
];
