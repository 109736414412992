import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import { useState, Suspense, lazy, createContext } from 'react';
import { useState, lazy, createContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { INACTIVE_TIMEOUT_DETAILS } from '../../constants/constants';
import InActiveSpeedBump from '../../components/InActiveSpeedBump/InActiveSpeedBump';
import UserLoggedInDetails from '../../utils/UserLoggedInDetails';
// import styles from '../../App.module.scss';
const ScrollToHash = lazy(() => import('../../components/ScrollToHash/ScrollToHash'));
const Header = lazy(() => import('../../components/Header/Header'));
const Footer = lazy(() => import('../../components/Footer/Footer'));
const SpeedBump = lazy(() => import('../../components/SpeedBump/SpeedBump'));
const Home = lazy(() => import('../Home/Home'));
const Accounts = lazy(() => import('../Accounts/Accounts'));
const ErrorPage = lazy(() => import('../ErrorPage/ErrorPage'));
const Faq = lazy(() => import('../Faq/Faq'));
const Sitemap = lazy(() => import('../Sitemap/Sitemap'));
const ContactUs = lazy(() => import('../ContactUs/ContactUs'));
const Respond = lazy(() => import('../Respond/Respond'));
const { timeout, warningTimeout } = INACTIVE_TIMEOUT_DETAILS;
export const ModalContext = createContext({});
const AppView = () => {
    const [modalObj, setModalObj] = useState({
        modalFlag: false,
        type: '',
        exLink: '',
    });
    const openModal = (type, url) => {
        setModalObj((prevData) => ({
            ...prevData,
            modalFlag: true,
            type: type,
            exLink: url,
        }));
    };
    const closeModal = () => {
        setModalObj((prevData) => ({
            ...prevData,
            modalFlag: false,
            type: '',
            exLink: '',
        }));
    };
    return (_jsx(UserLoggedInDetails, { children: _jsxs(ModalContext.Provider, { value: { openModal, closeModal }, children: [_jsx(ScrollToHash, {}), _jsx(InActiveSpeedBump, { timeout: timeout, warningTimeout: warningTimeout }), _jsx(Header, {}), _jsx("div", { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, {}) }), _jsx(Route, { path: "/myaccount", element: _jsx(Accounts, {}) }), _jsx(Route, { path: "/sitemap", element: _jsx(Sitemap, {}) }), _jsx(Route, { path: "/error", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "/pay-manage", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/oops" }) }), _jsx(Route, { path: "/oops", element: _jsx(ErrorPage, {}) }), _jsx(Route, { path: "/faq", element: _jsx(Faq, {}) }), _jsx(Route, { path: "/contact-us", element: _jsx(ContactUs, {}) }), _jsx(Route, { path: "/respond", element: _jsx(Respond, {}) })] }) }), _jsx(Footer, {}), _jsx(SpeedBump, { showModal: modalObj.modalFlag, hideModal: closeModal, type: modalObj.type, externalLink: modalObj.exLink })] }) }));
};
export default AppView;
